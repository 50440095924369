import React from 'react';
import useTestexchange from './useTestexchange';
import { FaArrowUpWideShort } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { FaEthereum } from "react-icons/fa6";
import { FaBitcoin } from "react-icons/fa";
const NumberFormat = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const TestExchange: React.FC = () => {
  const signal1 = localStorage.getItem("signal_1");
  const signal2 = localStorage.getItem("signal_2");
  const signal3 = localStorage.getItem("signal_3");
  const signal4 = localStorage.getItem("signal_4");
  const signal5 = localStorage.getItem("signal_5");
  const signal6 = localStorage.getItem("signal_6");
  const signal7 = localStorage.getItem("signal_7");
  const signal8 = localStorage.getItem("signal_8");
  const signalfil = localStorage.getItem("signal_fil");
  const signalbtc = localStorage.getItem("signal_btc");
  const signaleth = localStorage.getItem("signal_eth");
  const signalbuysel = localStorage.getItem("cn_busel");
  const signalethaskbid = localStorage.getItem("cn_ethask");
  const signalethaskbid22 = localStorage.getItem("cn_ethask22");
  const pish10 = localStorage.getItem("cn_pish10");
  const allbtc = localStorage.getItem("cn_allbtc");
  const pish50 = localStorage.getItem("cn_pish50");
  const pish20 = localStorage.getItem("cn_pish20");
  const askbideth22 = localStorage.getItem("cn_askbidseth22");
  const tarkibpishha = localStorage.getItem("cn_tarkibpishha");
  const pish5 = localStorage.getItem("cn_pish5");
  const pishbini15saniye = localStorage.getItem("cn_15saniye");
  const pish3 = localStorage.getItem("cn_pish3");
  const hamjahat = localStorage.getItem("cn_hamjahat");
  const pish3hamjahat = localStorage.getItem("cn_pish3hamjahat");
  const alleth = localStorage.getItem("cn_alleth");
  const erhhamjahatkam = localStorage.getItem("cn_erhhamjahatkam");
  const bitmakoskonande = localStorage.getItem("cn_bitmakoskonande");
  const pish10_5 = localStorage.getItem("cn_pish10_5");
  const pish100_400eth = localStorage.getItem("cn_pish100_400eth");
  const ethpish135 = localStorage.getItem("cn_ethpish135");
  const pish10tedad = localStorage.getItem("cn_pish10tedad");
  const pish20tedad = localStorage.getItem("cn_pish20tedad");
  const pish100_400btc = localStorage.getItem("cn_pish100_400btc");
  const tarkibpishha22 = localStorage.getItem("cn_tarkibpishha22");
  const confirmpish100400 = localStorage.getItem("cn_confirmpish100400");
  const filtarkib = localStorage.getItem("cn_filtarkib");
  // دریافت داده‌ها از localStorage

  // تابع برای پارس داده‌ها


  const orderbooksocket = localStorage.getItem("orderbooksocket");
  const {
    filAsks,
    filBids,
    ethAsks,
    ethBids,
    btcAsks,
    btcBids, filAskskaam,
    filBidskam,
    ethAskskam,
    ethBidskam,
    btcAskskam,
    btcBidskam, eth_all_bybit, eth_10_binance, allBtc, allBybit, btcByBitStatus, signalperiodStatus, orderbookperiodStatus, eth_20_binance, EthPriceBinanceStatus, ethPriceBinance, btcPriceBinanceStatus, btc_100_Bitstamp, Eth_100_Bitstamp, btc_400_Bitstamp, Eth_400_Bitstamp, btc_100_okx, Eth_100_okx, btc_400_okx, Eth_400_okx, btc_100_bybit, eth_100_bybit, btc_400_bybit, eth_400_bybit, btcPriceBinance } = useTestexchange();
  // console.log("btcPriceBinance", btcPriceBinance);
  // console.log("Status  periodStatus=> ",periodStatus)
  // console.log("****",signalStatus.signalStatus)
  // console.log('FIL Asks:', filAsks);
  // console.log('FIL Bids:', filBids);
  // console.log('ETH Asks:', ethAsks);
  // console.log('ETH Bids:', ethBids);
  // console.log('BTC Asks:', btcAsks);
  // console.log('BTC Bids:', btcBids);
  if (filAskskaam) {
    // console.log('First BTC Ask:', filAskskaam);
  } else {
    // console.log('BTC Asks is undefined or empty');
  }
  return (
    <div className="w-full min-h-fit text-gray-100 bg-white bg-opacity-15 rounded-md py-4">
      <div className='flex justify-center gap-20'>
        <div className='flex flex-col text-end'>
          <h1 className='whitespace-nowrap'>
            BTC
            <span className='text-yellow-500'>
              <span className={`text-yellow-600`}>{btcPriceBinance && btcPriceBinance.toFixed(2)}</span>
            </span>
          </h1>
          <h1 className='whitespace-nowrap'>
            ETH
            <span className='text-yellow-500'>
              <span className={`text-yellow-600`}>{ethPriceBinance && ethPriceBinance.toFixed(2)}</span>
            </span>
          </h1>
          <div>Signal Status BTC order: <span className={`${signal8 === "short" ? "text-red-700" : signal8 === "STRONG SHORT" ? "text-red-700" : "text-green-600"}`}>{signal8}</span></div>
          <div>Signal Status fil askbid: <span className={`${signalfil == "short" ? "text-red-700" : "text-green-600"}`}>{signalfil}</span></div>
          <div>Signal Status eth askbid: <span className={`${signaleth == "short" ? "text-red-700" : "text-green-600"}`}>{signaleth}</span></div>
          <div>Signal Status btc askbid: <span className={`${signalbtc == "short" ? "text-red-700" : "text-green-600"}`}>{signalbtc}</span></div>
          <div>Signal Status buysell: <span className={`${signalbuysel == "short" ? "text-red-700" : "text-green-600"}`}>{signalbuysel}</span></div>
          <div>pishbini15saniye: <span className={`${pishbini15saniye == "short" ? "text-red-700" : "text-green-600"}`}>{pishbini15saniye}</span></div>
          <div>pish333: <span className={`${pish3 == "short" ? "text-red-700" : "text-green-600"}`}>{pish3}</span></div>
          <div>pish3hamjahat: <span className={`${pish3hamjahat == "short" ? "text-red-700" : "text-green-600"}`}>{pish3hamjahat}</span></div>
          <div>hamjahat: <span className={`${hamjahat == "short" ? "text-red-700" : "text-green-600"}`}>{hamjahat}</span></div>
          <div>alleth: <span className={`${alleth == "short" ? "text-red-700" : "text-green-600"}`}>{alleth}</span></div>
          <div>erhhamjahatkam: <span className={`${erhhamjahatkam == "short" ? "text-red-700" : "text-green-600"}`}>{erhhamjahatkam}</span></div>
          <div>bitmakoskonande: <span className={`${bitmakoskonande == "short" ? "text-red-700" : "text-green-600"}`}>{bitmakoskonande}</span></div>
          <div>pish10_5: <span className={`${pish10_5 == "short" ? "text-red-700" : "text-green-600"}`}>{pish10_5}</span></div>
          <div>pish100_400eth: <span className={`${pish100_400eth == "short" ? "text-red-700" : "text-green-600"}`}>{pish100_400eth}</span></div>
          <div>pish100_400btc: <span className={`${pish100_400btc == "short" ? "text-red-700" : "text-green-600"}`}>{pish100_400btc}</span></div>
          <div>ethpish135: <span className={`${ethpish135 == "short" ? "text-red-700" : "text-green-600"}`}>{ethpish135}</span></div>
          <div>pish10tedad: <span className={`${pish10tedad == "short" ? "text-red-700" : "text-green-600"}`}>{pish10tedad}</span></div>
          <div>pish20tedad: <span className={`${pish20tedad == "short" ? "text-red-700" : "text-green-600"}`}>{pish20tedad}</span></div>
          <div>confirmpish100400: <span className={`${confirmpish100400 == "short" ? "text-red-700" : "text-white text-2xl"}`}>{confirmpish100400}</span></div>
        </div>

        <div className='text-end'>
          <div>Signal Status 1: <span className={`${signal1 == "short" ? "text-red-700" : "text-green-600"}`}>{signal1}</span></div>
          <div>Signal Status 2: <span className={`${signal2 == "short" ? "text-red-700" : "text-green-600"}`}>{signal2}</span></div>
          <div>Signal Status ETH order: <span className={`${signal7 === "short" ? "text-red-700" : signal7 === "STRONG SHORT" ? "text-red-700" : "text-green-600"}`}>{signal7}</span></div>
          <div>Signal Status 15m: <span className={`${signal3 === "SHORT" ? "text-red-700" : signal3 === "STRONG SHORT" ? "text-red-700" : "text-green-600"}`}>{signal3}</span></div>
          <div>Signal Status 30m: <span className={`${signal4 === "SHORT" ? "text-red-700" : signal4 === "STRONG SHORT" ? "text-red-700" : "text-green-600"}`}>{signal4}</span></div>
          <div>Signal Status 1h: <span className={`${signal5 === "SHORT" ? "text-red-700" : signal5 === "STRONG SHORT" ? "text-red-700" : "text-green-600"}`}>{signal5}</span></div>
          <div>Signal Status fil order: <span className={`${signal6 === "short" ? "text-red-700" : signal6 === "STRONG SHORT" ? "text-red-700" : "text-green-600"}`}>{signal6}</span></div>
          <div>ethaskbid: <span className={`${signalethaskbid == "short" ? "text-red-700" : "text-green-600"}`}>{signalethaskbid}</span></div>
          <div>ethaskbid22: <span className={`${signalethaskbid22 == "short" ? "text-red-700" : "text-green-600"}`}>{signalethaskbid22}</span></div>
          <div>pish5: <span className={`${pish5 == "short" ? "text-red-700" : "text-green-600"}`}>{pish5}</span></div>
          <div>pish10: <span className={`${pish10 == "short" ? "text-red-700" : "text-green-600"}`}>{pish10}</span></div>
          <div>pish20: <span className={`${pish20 == "short" ? "text-red-700" : "text-green-600"}`}>{pish20}</span></div>
          <div>pish50: <span className={`${pish50 == "short" ? "text-red-700" : "text-green-600"}`}>{pish50}</span></div>
          <div>tarkib: <span className={`${tarkibpishha == "short" ? "text-red-700" : "text-green-600"}`}>{tarkibpishha}</span></div>
          <div>eth forcheng: <span className={`${askbideth22 == "short" ? "text-red-700" : "text-green-600"}`}>{askbideth22}</span></div>
          <div>allbtcsignal: <span className={`${allbtc == "short" ? "text-red-700" : "text-green-600"}`}>{allbtc}</span></div>
          <div>tarkibpishha22: <span className={`${tarkibpishha22 == "short" ? "text-red-700" : "text-green-600"}`}>{tarkibpishha22}</span></div>
          <div>filtarkib: <span className={`${filtarkib == "short" ? "text-red-700" : "text-green-600"}`}>{filtarkib}</span></div>
          <div>Orderbook Status: <span className={`${orderbooksocket == "short" ? "text-red-700" : "text-green-600"}`}>{orderbooksocket}</span></div>

        </div>

        <div className='flex flex-col text-start ml-6'>
          <div className='flex'>
            <div className='w-1/2 pr-20 bg-red-200/30 backdrop-blur-lg p-6 rounded-lg border border-red-300'>
              <div className='flex flex-col mb-3'>
                <span>AskFIL:</span>
                <div className='text-red-700 text-2xl'>
                  {Array.isArray(filAsks) ? filAsks.map((ask, index) => (
                    <div key={index}>{NumberFormat.format(ask)}</div>
                  )) : NumberFormat.format(filAsks)}
                </div>
                <div className='text-red-700 text-2xl'>
                  {Array.isArray(filAskskaam) ? filAskskaam.map((ask, index) => (
                    <div key={index}>{NumberFormat.format(ask)}</div>
                  )) : NumberFormat.format(filAskskaam)}
                </div>
              </div>
              <div className='flex flex-col mb-3'>
                <span>AskETH:</span>
                <div className='text-red-700 text-2xl'>
                  {Array.isArray(ethAsks) ? ethAsks.map((ask, index) => (
                    <div key={index}>{NumberFormat.format(ask)}</div>
                  )) : NumberFormat.format(ethAsks)}
                </div>
                <div className='text-red-700 text-2xl'>
                  {Array.isArray(ethAskskam) ? ethAskskam.map((ask, index) => (
                    <div key={index}>{NumberFormat.format(ask)}</div>
                  )) : NumberFormat.format(ethAskskam)}
                </div>
              </div>
              <div className='flex flex-col mb-3'>
                <span>AskBTC:</span>
                <div className='text-red-700 text-2xl'>
                  {Array.isArray(btcAsks) ? btcAsks.map((ask, index) => (
                    <div key={index}>{NumberFormat.format(ask)}</div>
                  )) : NumberFormat.format(btcAsks)}
                </div>
                <div className='text-red-700 text-2xl'>
                  {Array.isArray(btcAskskam) ? btcAskskam.map((ask, index) => (
                    <div key={index}>{NumberFormat.format(ask)}</div>
                  )) : NumberFormat.format(btcAskskam)}
                </div>
              </div>
            </div>
            <div className='w-1/12 bg-gray-300 mx-8'></div>
            <div className='w-1/2 pl-20 bg-green-200/30 backdrop-blur-lg p-6 rounded-lg border border-green-300'>
              <div className='flex flex-col mb-3'>
                <span>BidsFIL:</span>
                <div className='text-green-900 text-2xl'>
                  {Array.isArray(filBids) ? filBids.map((bid, index) => (
                    <div key={index}>{NumberFormat.format(bid)}</div>
                  )) : NumberFormat.format(filBids)}
                </div>
                <div className='text-green-900 text-2xl'>
                  {Array.isArray(filBidskam) ? filBidskam.map((bid, index) => (
                    <div key={index}>{NumberFormat.format(bid)}</div>
                  )) : NumberFormat.format(filBidskam)}
                </div>
              </div>
              <div className='flex flex-col mb-3'>
                <span>BidsETH:</span>
                <div className='text-green-900 text-2xl'>
                  {Array.isArray(ethBids) ? ethBids.map((bid, index) => (
                    <div key={index}>{NumberFormat.format(bid)}</div>
                  )) : NumberFormat.format(ethBids)}
                </div>
                <div className='text-green-900 text-2xl'>
                  {Array.isArray(ethBidskam) ? ethBidskam.map((bid, index) => (
                    <div key={index}>{NumberFormat.format(bid)}</div>
                  )) : NumberFormat.format(ethBidskam)}
                </div>
              </div>
              <div className='flex flex-col mb-3'>
                <span>BidsBTC:</span>
                <div className='text-green-900 text-2xl'>
                  {Array.isArray(btcBids) ? btcBids.map((bid, index) => (
                    <div key={index}>{NumberFormat.format(bid)}</div>
                  )) : NumberFormat.format(btcBids)}

                </div>
                <div className='text-green-900 text-2xl'>
                  {Array.isArray(btcBidskam) ? btcBidskam.map((bid, index) => (
                    <div key={index}>{NumberFormat.format(bid)}</div>
                  )) : NumberFormat.format(btcBidskam)}

                </div>
              </div>
            </div>
          </div>
        </div>





        {/* </div> */}
      </div>
      <div className='flex w-full  bg-opacity-15 p-2 rounded-lg justify-center gap-5 my-3'>
        {/* <div className='bg-gray-700 w-4/12 rounded-lg'>
          <h2 className='text-center py-2'>Bit Coin</h2>
          <hr />
          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{allBtc.length > 0 ? allBtc[0].a : ''}</span>
            <span> sell</span>
          </div>
          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{allBtc.length > 0 ? allBtc[0].b : ''}</span>
            <span> buy</span>
          </div>
          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{allBtc.length > 0 ? allBtc[0].q : ''}</span>
            <span> qty</span>
          </div>
          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{allBtc.length > 0 ? allBtc[0].p : ''}</span>
            <span> pish </span>
          </div>
          
        </div> */}
        <div className={`bg-gray-700 bg-opacity-20 w-4/12 rounded-lg  ${eth_all_bybit && eth_all_bybit.j == 1 ? "bg-green-600 border-green-700 border-1" : "bg-red-600 border-red-700 border-1"}`}>
          <h2 className='text-center py-2 text-cyan-500'> <span>Biy-bit  ETH</span>
          </h2>
          <span>
            {/* <FaEthereum /> */}
          </span>
          {/* <FaArrowUpWideShort className={`${allBybit && allBybit.j === 1 && "text-green-600"}`} /> */}

          <hr />
          <div className={`flex justify-between px-5 my-3 min-w-full ${eth_all_bybit && eth_all_bybit.j == 1 ? "ls" : "dfjf"}`}>
            <span className={`${eth_all_bybit && eth_all_bybit.j == 1 ? "text-green-600" : "text-red-600"}`}>{eth_all_bybit ? eth_all_bybit.a : ''}</span>
            <span> sell</span>
          </div>
          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span className={`${eth_all_bybit && eth_all_bybit.j == 1 ? "text-green-600" : "text-red-600"}`}>{eth_all_bybit ? eth_all_bybit.b : ''}</span>
            <span> buy</span>
          </div>
          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{eth_all_bybit ? eth_all_bybit.q : ''}</span>
            <span> qty</span>
          </div>
          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{eth_all_bybit ? eth_all_bybit.p : ''}</span>
            <span> pish </span>
          </div>
        </div>
        <div className={`bg-gray-700 bg-opacity-20 w-4/12 rounded-lg  ${allBybit && allBybit.j == 1 ? "bg-green-600 border-green-700 border-1" : "bg-red-600 border-red-700 border-1"}`}>
          <h2 className='text-center py-2 text-yellow-500'>Biy-bit  BTC
          </h2>
          {/* <FaArrowUpWideShort className={`${allBybit && allBybit.j === 1 && "text-green-600"}`} /> */}

          <hr />
          <div className={`flex justify-between px-5 my-3 min-w-full ${allBybit && allBybit.j == 1 ? "ls" : "dfjf"}`}>
            <span className={`${allBybit && allBybit.j == 1 ? "text-green-600" : "text-red-600"}`}>{allBybit ? allBybit.a : ''}</span>
            <span> sell</span>
          </div>
          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span className={`${allBybit && allBybit.j == 1 ? "text-green-600" : "text-red-600"}`}>{allBybit ? allBybit.b : ''}</span>
            <span> buy</span>
          </div>
          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{allBybit ? allBybit.q : ''}</span>
            <span> qty</span>
          </div>
          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{allBybit ? allBybit.p : ''}</span>
            <span> pish </span>
          </div>
        </div>
        <div className={`bg-gray-700 bg-opacity-20 w-4/12 rounded-lg  ${allBtc && allBtc.j == 1 ? "bg-green-600 border-green-700 border-1" : "bg-red-600 border-red-700 border-1"}`}>
          <h2 className='text-center py-2 text-yellow-500'>Bitcoin
          </h2>
          <hr />
          <div className={`flex justify-between px-5 my-3 min-w-full ${allBtc && allBtc.j === 1 ? "ls" : "dfjf"}`}>
            <span className={`${allBtc && allBtc.j == 1 ? "text-green-600" : "text-red-600"}`}>{allBtc ? allBtc.a : ''}</span>
            <span> sell</span>
          </div>
          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span className={`${allBtc && allBtc.j == 1 ? "text-green-600" : "text-red-600"}`}>{allBtc ? allBtc.b : ''}</span>
            <span> buy</span>
          </div>
          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{allBtc ? allBtc.q : ''}</span>
            <span> qty</span>
          </div>
          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{allBtc ? allBtc.p : ''}</span>
            <span> pish </span>
          </div>
        </div>
      </div>
      <div className='flex w-full bg-opacity-15 p-10 rounded-lg justify-center gap-5   my-10'>
        <div className='bg-gray-700 w-3/12 rounded-lg '>
          <h2 className='text-center py-2 text-white'><span className=''>Bitstamp</span>   <span className='text-yellow-600'>BTCUSDT</span>  100$
          </h2>
          <hr />

          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{btc_100_Bitstamp ? btc_100_Bitstamp.p : ''}</span>
            <span> pish </span>
          </div>
        </div>
        <div className='bg-gray-700 w-3/12 rounded-lg'>
          <h2 className='text-center py-2 text-white'>Bitstamp <span className='text-yellow-600'>BTCUSDT</span>  400$
          </h2>
          <hr />

          <div className='flex justify-between px-5 my-3 min-w-full  '>
            <span>{btc_400_Bitstamp ? btc_400_Bitstamp.p : ''}</span>
            <span> pish </span>
          </div>
        </div>
        <div className='bg-gray-700 w-3/12 rounded-lg'>
          <h2 className='text-center py-2 text-white'>Bitstamp <span className='text-cyan-600'>ETHUSDT</span>  100$
          </h2>
          <hr />

          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{Eth_100_Bitstamp ? Eth_100_Bitstamp.p : ''}</span>
            <span> pish </span>
          </div>
        </div>
        <div className='bg-gray-700 w-3/12 rounded-lg'>
          <h2 className='text-center py-2 text-white'>Bitstamp <span className='text-cyan-600'>ETHUSDT</span>  400$
          </h2>
          <hr />

          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{Eth_400_Bitstamp ? Eth_400_Bitstamp.p : ''}</span>
            <span> pish </span>
          </div>
        </div>


      </div>


      {/*     btc_100_okx , Eth_100_okx , btc_400_okx ,Eth_400_okx */}

      <div className='flex w-full bg-opacity-15 p-10 rounded-lg justify-center gap-5 my-10'>
        <div className='bg-gray-700 w-3/12 rounded-lg'>
          <h2 className='text-center py-2 text-white'>OKX <span className='text-yellow-600'>BTCUSDT</span>  100$
          </h2>
          <hr />

          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{btc_100_okx ? btc_100_okx.p : ''}</span>
            <span> pish </span>
          </div>
        </div>
        <div className='bg-gray-700 w-3/12 rounded-lg'>
          <h2 className='text-center py-2 text-white'>OKX <span className='text-yellow-600'>BTCUSDT</span>  400$
          </h2>
          <hr />

          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{btc_400_okx ? btc_400_okx.p : ''}</span>
            <span> pish </span>
          </div>
        </div>

        <div className='bg-gray-700 w-3/12 rounded-lg'>
          <h2 className='text-center py-2 text-white'>BINANCE <span className='text-cyan-600'>ETHUSDT</span>  20$
          </h2>
          <hr />

          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{eth_20_binance ? eth_20_binance.p : ''}</span>
            <span> pish </span>
          </div>
        </div>
        <div className='bg-gray-700 w-3/12 rounded-lg'>
          <h2 className='text-center py-2 text-white'>BINANCE <span className='text-cyan-600'>ETHUSDT</span>  10$
          </h2>
          <hr />

          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{eth_10_binance ? eth_10_binance.p : ''}</span>
            <span> pish </span>
          </div>
        </div>


      </div>

      <div className='flex w-full  bg-opacity-15 p-10 rounded-lg justify-center gap-5 my-10'>
        <div className='bg-gray-700 w-3/12 rounded-lg'>
          <h2 className='text-center py-2 text-white'>Bybit <span className='text-yellow-600'>BTCUSDT</span>  100$
          </h2>
          <hr />

          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{btc_100_bybit ? btc_100_bybit.p : ''}</span>
            <span> pish </span>
          </div>
        </div>
        <div className='bg-gray-700 w-3/12 rounded-lg'>
          <h2 className='text-center py-2 text-white'>Bybit <span className='text-yellow-600'>BTCUSDT</span>  400$
          </h2>
          <hr />

          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{btc_400_bybit ? btc_400_bybit.p : ''}</span>
            <span> pish </span>
          </div>
        </div>
        <div className='bg-gray-700 w-3/12 rounded-lg'>
          <h2 className='text-center py-2 text-white'>Bybit <span className='text-cyan-600'>ETHUSDT</span>  100$
          </h2>
          <hr />

          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{eth_100_bybit ? eth_100_bybit.p : ''}</span>
            <span> pish </span>
          </div>
        </div>
        <div className='bg-gray-700 w-3/12 rounded-lg'>
          <h2 className='text-center py-2 text-white'>Bybit <span className='text-cyan-600'>ETHUSDT</span>  400$
          </h2>
          <hr />

          <div className='flex justify-between px-5 my-3 min-w-full '>
            <span>{eth_400_bybit ? eth_400_bybit.p : ''}</span>
            <span> pish </span>
          </div>
        </div>


      </div>












    </div>
  );
};

export default TestExchange;
